<template>
    <div class="footer">
        <div class="container flex">
            <p>&copy; 2020 Justin Trout</p>
            <!--<p id="contact">
                <a href="javascript:;" v-on:click="contactShow">
                Contact <svg alt="Email Me" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" /></svg>
            </a></p>-->
        </div>
        <div class="footerbg"></div>
    </div>
</template>

<script>
    export default {
        methods: {
            contactShow() {
                this.$modal.show('contact-modal');
            }
        }
    }
</script>
<template>
    <div class="home">
        <div class="home-top">
            <div class="flex left container">
                <div class="container-small">
                    <h1 class="gtext" v-view.once>Hi, I'm Justin</h1>
                    <h2 class="h5 regular" v-view.once>User-Centered Designer of Things</h2>
                </div>
            </div>
        </div>
        <div class="home-content" id="profile">
            <div class="container">
                <div class="section-big half-screen reverse">
                    <div class="container" v-view.once>
                        <h3>About me</h3>
                        <h4 class="h6 quote">I'm a UI enthusiast and UX advocate with a passion for clean lines and voluptuous curves. With over a decade of experience in visual design, I have created content across a wide range of mediums.</h4>
                    </div>
                    <div>
                        <div class="portrait" v-view.once>
                            <picture>
                                <source type="image/webp" srcset="/images/portfolio/template/profile.webp">
                                <source type="image/png" srcset="/images/portfolio/template/profile.png">
                                <img src="/images/portfolio/template/profile.jpg" alt="portrait">
                            </picture>
                        </div>
                        <div class="portrait-block" v-view.once></div>
                    </div>
                </div>
                <div class="half-screen">
                    <div class="section text-center" v-view.once>
                        <div class="card hovered">
                            <div class="card-ttl">
                                <h3 class="no-margin">I'm a Designer</h3>
                            </div>
                            <div class="card-content">
                                <p>As a designer I have had the pleasure of working with high-profile Fortune 500s down to the local mom-and-pop businesses. As always, my goal with any creative project is to develop impactful, visually stunning, high-quality designs.</p>
                            </div>
                        </div>
                    </div>
                    <div class="section text-center" v-view.once>
                        <div class="card hovered">
                            <div class="card-ttl">
                                <h3 class="no-margin">I <span class="text-svg"><img src="/images/portfolio/template/heart.svg" alt="love" /></span> the Front-End</h3>
                            </div>
                            <div class="card-content">
                                <p>I have been honing my UI design and front-end skills since the dawn of CSS. Building and maintaining sites for external clients and internal projects has exposed me to a variety of systems, frameworks, and user types.</p>
                            </div>
                        </div>
                    </div>
                    <div class="section text-center" v-view.once>
                        <div class="card hovered">
                            <div class="card-ttl">
                                <h3 class="no-margin">UX is my Creed</h3>
                            </div>
                            <div class="card-content">
                                <p>User Experience is a passion of mine and I believe that a good experience is key to engagement. Data-influenced design is a core philosophy in everything I create, while testing and analytics help validate my hypotheses.</p>
                            </div>
                        </div>
                    </div>
                    <div class="section text-center" v-view.once>
                        <div class="card hovered">
                            <div class="card-ttl">
                                <h3 class="no-margin">I Build Systems</h3>
                            </div>
                            <div class="card-content">
                                <p>When building systems it is my goal to merge functionality and data to create tools that fulfill a user's needs. DevOps, APIs, and automation are at the forefront of every systems project I work on.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="section-big" v-view.once>
                <p class="text-center">
                    <router-link class="btn" to="/work">Examples and Case Studies</router-link>
                </p>
            </div>-->
            </div>
        </div>
        <!--<div class="container">
            <hr />
        </div>-->
        <div class="home-contact container">
            <div class="section-big half-screen reverse">
                <div>
                </div>
                <div>
                    <contactform />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import contactform from '../components/ContactForm.vue';
    export default{
        name: 'home',
        components: {
            contactform
        },
        data (){
            return{
                title:'About Me'
            }
        },
        mounted:
            function () {
                var cardTtl = document.getElementsByClassName("card-ttl");
                function randNum() {
                    return Math.floor(Math.random() * (310 - 160) + 160);
                }
                for (var i = 0, max = cardTtl.length; i < max; i++) {
                    var ttlHue = randNum();
                    cardTtl[i].setAttribute("style", "background: linear-gradient(-135deg, hsla(" + ttlHue + ", 80%, 45%, .6) 0%, hsla(" + (ttlHue + 54) + ", 80%, 45%, .6) 100%);");
                }
        },
        metaInfo: {
            title: 'About Me',
            meta: [
                {
                    name: 'description',
                    content: 'The Home Page of Justin Trout.'
                },
                {
                    property: 'og:description',
                    content: 'The Home Page of Justin Trout.'
                }
            ]
        }
    }
</script>
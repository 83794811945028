<template>
    <div class="work">
        <div class="flex center work-content">
            <div class="container text-center" v-view.once>
                <h1 class="gtext">My Work</h1>
                <h2 class="no-margin h6 text-center">Examples and case studies of some projects I have contributed to will be found below.</h2>
            </div>
        </div>
        <gallery />
    </div>
</template>

<script>
import gallery from '../components/Gallery.vue';
export default{
	name:'work',
    components: {
        gallery,
    },
    data() {
		return{
            title: 'work',
		}
    },
    metaInfo: {
        title: 'Work I do',
        meta: [
            {
                name: 'description',
                content: 'Portfolio and case studies'
            },
            {
                property: 'og:description',
                content: 'Portfolio and case studies'
            }
        ]
    }
}

</script>
<template>
    <div class="section">
        <form class="contact-form" @submit.prevent="validateSubmit">
            <div v-if="form_complete" class="text-center">
                <h4>Thank you</h4>
                <p class="text-center">I will get back with you soon.</p>
            </div>
            <div v-else>
                <h4 class="h3 text-center" v-view.once>Let's Work Together</h4>
                <div class="social" v-view.once>
                    <ul>
                        <li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/justin-trout/"><img src="/images/portfolio/template/linkedin.svg" alt="LinkedIn" /></a></li>
                        <li><a rel="noreferrer" target="_blank" href="https://twitter.com/dubsaru"><img src="/images/portfolio/template/twitter.svg" alt="Twitter" /></a></li>
                    </ul>
                </div>
                <div v-view.once>
                    <label for="form_name">Name</label>
                    <input id="form_name" type="text" name="form_name" v-model="form_name" @blur="validateName">
                    <transition appear name="fade">
                        <p class="form-error" v-if="errors.form_name">
                            Name cannot be empty.
                        </p>
                    </transition>
                </div>
                <div v-view.once>
                    <label for="form_email">Email</label>
                    <input id="form_email" type="email" name="form_email" v-model="form_email" @blur="validateEmail">
                    <transition name="fade">
                        <p class="form-error" v-if="errors.form_email">
                            Please enter a valid email.
                        </p>
                    </transition>
                </div>
                <div v-view.once>
                    <label for="form_message">Message</label>
                    <textarea id="form_message" name="form_message"></textarea>
                </div>
                <div class="text-center" v-view.once>
                    <input class="btn tiny" type="submit" value="Send Message">
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import emailjs from 'emailjs-com';
    export default {
        data() {
            return {
                form_name: '',
                form_email: '',
                form_complete: false,
                errors: {
                    form_name: false,
                    form_email: false
                }
            }
        },
        methods: {
            sendEmail: (e, ref) => {
                var service_id = "default_service";
                var template_id = "justintrout_me_contact";
                emailjs.sendForm(service_id, template_id, e.target, 'user_9ZsiGuMv0YeJSzFV5HfMX')
                    .then(() => {
                        ref.formComplete();
                    });
            },
            validateEmail: function () {
                var isValid = isValidEmail(this.form_email);
                this.errors.form_email = !isValid;
            },
            validateName: function () {
                var isValid = this.form_name.length > 0;
                this.errors.form_name = !isValid;
            },
            validateSubmit: function (e) {
                var errorName = this.form_name.length > 0;
                this.errors.form_name = !errorName;
                var errorEmail = isValidEmail(this.form_email);
                this.errors.form_email = !errorEmail;
                var ref = this;
                if (errorEmail == true && errorName == true) {
                    this.sendEmail(e, ref);
                }
            },
            formComplete: function () {
                this.form_complete = true;
            }
        }
    }
    function isValidEmail(form_email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(form_email);
    }
</script>

<style scoped>
</style>
import Vue from '../node_modules/vue'
import App from './App.vue'
import router from './routes.js'
import checkView from 'vue-check-view'
import VModal from 'vue-js-modal'

Vue.use(checkView)
Vue.use(VModal)

Vue.config.productionTip = true

new Vue({
    router,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app');
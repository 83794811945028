// ROUTES
import Vue from '../node_modules/vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import work from './pages/Work.vue'
import home from './pages/Home.vue'
import casestudy from './components/Casestudy'
import notFound from './components/NotFound'

Vue.use(VueRouter)
Vue.use(Meta)

const router = new VueRouter({
    routes: [
        {
            path: '/home/',
            alias: '/',
            name: 'home',
            component: home,
            meta: { title: 'About Me' }
        },
        {
            path: '/work/',
            name: 'work',
            component: work,
            meta: { title: 'Work I do' }
        },
        {
            path: '/work/:Cid',
            name: 'casestudy',
            component: casestudy,
            meta: { title: 'Case Study' }
        },
        {
            path: '/404',
            name:'notFound',
            component: notFound,
            meta: { title: 'Error 404 Page' }
        },
        {
            path: '*',
            redirect: '/404'
        }
    ],
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        const position = {}
        if (to.hash) {
            position.selector = to.hash

            if (to.hash === '#profile') {
                position.offset = { y: 100 }
            }

            if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
                return position
            }

            return false
        }
        else {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({ x: 0, y: 0 })
                }, 300)
            })
        }
    }
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
});

export default router;
<template>
    <div id="AppMain">
        <navarea />
        <transition name="component-fade" mode="out-in">
            <router-view />
        </transition>
        <footerarea />
    </div>
</template>

<script>
    import navarea from './components/Header.vue';
    import footerarea from './components/Footer.vue';

    export default {
        name: 'AppMain',
        components: {
            navarea,
            footerarea
        },
        metaInfo: {
          title: 'Portfolio of Justin Trout',
        },
        methods: {
            modalAssist() {
                document.body.classList.toggle("vm--modal-overflow");
            },
            stickyNav() {
                var navbar = document.getElementById("nav");
                var pageOffset = navbar.offsetTop;
                if (window.pageYOffset >= (pageOffset + 1)) {
                    navbar.classList.add("sticky")
                } else {
                    navbar.classList.remove("sticky");
                }
            }
        },
        mounted: function () {
            window.addEventListener('scroll', this.stickyNav);
        }
    };
</script>

<style>
    @import './assets/Styles.min.css';
</style>

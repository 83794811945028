<template>
    <div v-if="dataReady" class="gallery">
        <div v-for="(galleryItem, index) in galleryItems" class="gallery-grid" :key="index">
            <div class="half-screen container gallery-item">
                <div class="section-big container" v-view.once>
                    <div class="item-img container-small">
                        <router-link :to="{path: galleryItem.csid}" append class="anchor-reset">
                            <img :src="galleryItem.thumbnail" :alt="galleryItem.title" />
                        </router-link>
                        <div class="gimg-bg"></div>
                    </div>
                </div>
                <div class="section-big container" v-view.once>
                    <div class="item-content container-small">
                        <h3 class="h4">{{ galleryItem.title }}</h3>
                        <p>{{ galleryItem.description }}</p>
                        <router-link :to="{path: galleryItem.csid}" append class="btn tiny">
                            View Details
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'gallery',
        data() {
            return {
                galleryItems: [
                    {
                        csid: 'dwwtcwebsite',
                        title: 'DWWTC Website',
                        description: 'Created with ASP.NET and MaterializeCss. Updated for accessibility, data-restructure, improved cart experience, and user personas.',
                        thumbnail: '/images/portfolio/gallery/dwwtc/dwwtc-website-thumb.png',
                        skills: ['UX', 'css', 'js', 'html', 'api', 'prototyping', 'a/b testing', 'analytics']
                    },
                    {
                        csid: 'adminportal',
                        title: 'DWWTC Admin Portal',
                        description: 'Created in ASP.NET, features included user and course management, scheduling, registration, CMS, workflow management and more.',
                        thumbnail: '/images/portfolio/gallery/dwwtc/dwwtcadmin-thumb.png',
                        skills: ['css', 'js', 'html', 'prototyping']
                    },
                    {
                        csid: 'marketingautomation',
                        title: 'Marketing Automation',
                        description: 'Building an integrated system to automate communications, reduce overhead, consolidate data and improve analytic capabilities.',
                        thumbnail: '/images/portfolio/gallery/dwwtc/htmlemailsthumb.png',
                        skills: ['css', 'html', 'email', 'deliverability', 'tracking']
                    },
                    {
                        csid: 'ondemand',
                        title: 'On-Demand Player',
                        description: 'Custom-built responsive HTML5 player for on-demand learning. Displays slides with captions and audio all synchronized with the scrub bar.',
                        thumbnail: '/images/portfolio/gallery/dwwtc/livelabs-thumb.png',
                        skills: ['css', 'js', 'html', 'audio']
                    },
                    {
                        csid: 'dwwmedia',
                        title: 'DWWMedia',
                        description: 'I have designed posters, flyers, folders, brochures, vehicle wraps, conference booths, marketing materials, just to name a few examples.',
                        thumbnail: '/images/portfolio/gallery/dwwm/dwwm-thumb.png',
                        skills: ['illustrator', 'photoshop', 'photography', 'print']
                    }
                ],
                dataReady: true
            }
        },
        mounted:
            function () {
                var ttls = document.getElementsByClassName("gimg-bg");
                function randNum() {
                    return Math.floor(Math.random() * (310 - 160) + 160);
                }
                for (var i = 0, max = ttls.length; i < max; i++) {
                    var ttlHue = randNum();

                    ttls[i].setAttribute("style", "background: linear-gradient(-135deg, hsla(" + ttlHue + ", 100%, 45%, .2) 0%, hsla(" + (ttlHue + 54) + ", 100%, 45%, .2) 100%);");
                }
            },
        methods: {
            itemDetail(csId) {
                this.$router.push({ name: 'casestudy', params: { Cid: csId } });
            }
        }
    }
</script>
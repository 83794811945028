<template>
    <div>
        <div class="ttl-container text-center">
            <div class="flex center around cs-ttl">
                <div class="flex center container">
                    <h1 class="no-margin">{{ caseStudyObj.title }}</h1>
                </div>
                <div class="flex cs-details container">
                    <div v-for="(csDetail, name) in caseStudyObj.details[0]" :key="csDetail.name">
                        <p class="d-ttl">{{ name }}</p>
                        <p class="text-center no-margin">{{ csDetail }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="casestudy-content">
            <div v-for="(contentItems, index) in contentObj" :key="contentItems.index" class="section">
                <h2 class="container-small h3" v-view.once>{{ index }}</h2>
                <template v-for="contentItem in contentItems">
                    <template v-for="contentData in contentItem">
                        <p class="container-small" :key="contentData.text.id" v-view.once>
                            {{ contentData.text }}
                        </p>
                        <template v-if="Array.isArray(contentData.examples) === true">
                            <ul :key="contentData.examples.id" class="flex section examples">
                                <li v-for="(example, index) in contentData.examples" :key="index.contentDataEx" v-view.once>
                                    <img v-on:click="imgShow(example.name)" :src="example.name" />
                                </li>
                                <li v-for="(vexample, index) in contentData.vexamples" :key="index.vexample" v-view.once class="vcontainer">
                                    <div v-on:click="vShow(vexample)"></div>
                                    <iframe width="560" height="315" :src="vexample" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </li>
                            </ul>
                        </template>
                        <template  v-else-if="Array.isArray(contentData.vexamples) === true">
                            <ul :key="contentData.vexamples.id" class="flex section examples">
                                <li v-for="(vexample, index) in contentData.vexamples" :key="index.vexample" v-view.once class="vcontainer">
                                    <div v-on:click="vShow(vexample)"></div>
                                    <iframe width="560" height="315" :src="vexample" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </li>
                            </ul>
                        </template>
                    </template>
                </template>
            </div>
        </div>
        <modal name="img-modal" @before-open="modalAssist" @before-close="modalAssist">
            <div class="modal-content">
                <a target="_blank" :href="modalImg" class="block"><img id="modalImage" class="block" :src="modalImg" /></a>
                <a class="mclose" @click="$modal.hide('img-modal')">Close</a>
            </div>
        </modal>
        <modal name="vid-modal">
            <div class="modal-content">
                <iframe id="modalVid" :src="modalVid" width="560" height="315" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <a class="mclose" @click="$modal.hide('vid-modal')">Close</a>
        </modal>
    </div>
</template>

.<script>
    export default {
        name: 'case-study',
        data() {
            return {
                modalImg: '',
                modalVid: '',
                ttlName:'',
                casestudyItems: [{
                    csid: 'dwwtcwebsite',
                    title: 'DWWTC Website',
                    titleImage: '/images/portfolio/gallery/dwwtc/website/homepage.jpg',
                    details: [{
                        role: 'UX & UI Designer',
                        platforms: 'Web & Mobile',
                        tools: 'Illustrator, Axure, Photoshop, Visual Studio',
                        duration: '1 Year+    '
                    }],
                    content: [{
                        overview: [
                            [{ text: 'The primary goal for this project was to add new event object types to the system. However, we ended up accomplishing much more as we included several other modifications in this update.' }],
                            [{ text: 'Our team consisted of a back-end developer, our marketing lead, and myself as the UX/UI designer and front-end developer.' }]
                        ],
                        goals: [
                            [{ text: 'The primary goal of this project was to introduce a new "event" object to the set of core event objects in the system. This change would impact all the portals, satellite sites, and services. ' }],
                            [{ text: 'There were also outstanding projects or opportunities that needed to be addressed. Our old site had been written in a version of .Net that did not support TLS 1.2 natively and prior versions were being deprecated. For solve this problem, we would need to update everything to use .Net 4.6 by default.' }],
                            [{ text: 'Feedback indicated that we would need to get a better understanding of who our purchasers and students were and how they wanted to register for classes. This set the precedent that we should attempt to define some personas to assist us in establishing user journeys for each client type.' }],
                            [{ text: 'The registration processes had previously been identified as a pain point for several clients and we wanted to address some of the issues and alleviate confusion.' }],
                            [{ text: 'I had recently configured marketing automation and CRM solutions and we wanted to integrate them with the website to leverage better tracking and content management.' }],
                            [{ text: 'This project was getting very large and we needed to set some boundaries and define project scope. We leveraged needs assessments through surveys and user interviews to help us concentrate on what was important and to establish basic workflows.' }]
                        ],
                        research: [
                            [{ text: 'Our first step in research was to look at our current website and the data we had to understand the behavior of prospective clients. For this we relied heavily on client feedback, analytics, heat maps, and user recordings.' }],
                            [{ text: 'Competitive analysis also played a major role in helping us understand trends in training delivery, presentation, and accessibility. We looked at major national "IT" training brands and local competitors to help understand our market and where we would be best represented.' }],
                            [{
                                text: 'We wanted to make sure that when we developed the content and URL structure that we would be using terms and phrases that are commonly used in popular industry-related searches. For this we relied heavily on google trends and analyzing page content from our competitors.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/website/competitive-analysis.jpg' }]
                            }],
                            [{ text: 'We found that not all visitors to the site were purchasers and we wanted to identify our purchasers from our students and researchers. During this step we compared past registration data and analytics to payment data so that we could investigate the frequency and under what circumstances students were also the purchasers.' }],
                            [{
                                text: 'To discover how prospective clients used the site we would rely heavily on analytics, heatmaps, and feedback. We found that the heatmaps, combined with the user-flow mapping in google analytics helped but the real winner was the visitor recordings. With these we were able to watch a user\'s journey from start-to-finish. This allowed us insight as to when users would idle, how they would scroll, and when they would abandon the site entirely.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/website/discovery-analytics.jpg' }]
                            }],
                            [{ text: 'With the trend of mobile web and google indexing we knew we needed to ensure that the site design would be mobile-friendly. When looking at the data it was evident that a small portion of the visitors would view the site on mobile but would almost never purchase from mobile.' }],
                            [{ text: 'We gained substantial insight from our customer contact sources. Live-chat logs revealed several of the troubles plaguing our visitors. The class exit surveys we were able to obtain highlighted opportunities for delivery but contained little usable information about the registration experience.' }],
                            [{ text: 'Interviews with sales staff were conducted to understand their customer pain points. This gave us insight to dialogues that occur outside of the chat and exit surveys since we did not keep phone recordings at the time.' }],
                            [{ text: 'Through research we were able to identify some obvious and some not so obvious pain points for visitors and potential clients. We had six registration portals accessed via multiple entry points which was confusing and redundant. The site\'s mobile experience was inconsistent and broken in some cases.' }],
                            [{ text: 'Conversion tracking was missing, and the old sites were built in a way that the registration process could not be fully tracked. Portions of the site structure were redundant and there were opportunities to reduce unnecessary complexity. Recurring questions in our chat logs pointed us to places where information was lacking and to opportunities where we could increase communication effectiveness.' }]
                        ],
                        ideation: [
                            [{
                                text: 'While researching the old website we identified a few areas that did not need much iteration, namely our certifications page, our site search, and the course outlines. In our interviews with the Sales team we were informed that the certifications page was heavily used as a guide for certification planning so we would not be changing that, merely updating it for mobile use. We also doubled down on our site search, adding it to our schedule, and course list pages as we had noticed it used more so than the main navigation.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/website/discovery-assessments.jpg' }]
                            }],
                            [{ text: 'Looking at data from the competitive analysis revealed some common trends.  With so many classes and terms that potential registrants were unfamiliar with we needed ways to help interpret the information. We decided to lean heavily on the keywords for site search, expanding common terms and categories so that visitors could find the classes they wanted with a wider variety of terms.' }],
                            [{
                                text: 'The company colors were outdated and needed a bit more vibrance, especially when it came to digital media. We wanted to bring more saturation to the existing color palette and add an extra color for identification and accent purposes. Logos were modified and created to accommodate social media and print needs.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/website/styles.jpg' }]
                            }],
                            [{ text: 'Other opportunities were discovered in our technology stack. While we continued to work with a C#/.Net backend due to familiarity, we migrated to the newest version of .Net MVC. This would mitigate some impact on the back-end code and would allow us to work in parallel. When it came to the front-end, we again wanted to leverage a framework to ease development. We determined that our options should include a modern look and feel, a SASS source, and it should have feature parity with other popular frameworks. After researching our options, we decided that MaterializeCss fit our needs better than other frameworks.' }],
                            [{
                                text: 'Prior to continuing with development, we wanted to define personas for the purpose of user-journey mapping. Defining personas began with identifying the types of transactions that occur during registration and the interactions required. Some registrants would require weeks of authorization, where others would simply swipe their card. On top of that, we had to accommodate for instances where the registrant was not the purchaser.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/website/preuserjourney.jpg' }]
                            }],
                            [{ text: 'We had a wide variety of courses and technologies on the site that would benefit from additional classification. We would organize our training partners into generalized categories to see what courses aligned with or different types of students. Combined with our demographic analytics we were able to identify three different personas to speak to, The Individual, The Corporate Manager, and the IT specialist.' }],
                            [{ text: 'After having identified our persona types, we wanted to tackle the unification of our registration portals. We went through several iterations of how this could be addressed. In the end we opted for a multi-step process that allowed registrants to review their class information and add discount or registration codes prior to inputting payment information. This would allow us more granularity when it came to tracking and hopefully reduce the amount of post-registration corrections that had to be made.' }],
                            [{ text: 'After the registration user-flow was determined, we could flush out the logic and behavior. The addition of more payment options allowed us to accommodate nearly every registrant type outside of our Individual learners who would typically need to go through their respective grant agency.' }],
                            [{
                                text: 'We were finally at a point where we could begin mapping out the content beyond the crucial systems. We created user flow diagrams and simple site maps to illustrate the user journey and content flow of the site. We decided that we would design the site as the homepage being a launch-pad into the personas and each persona page as a mini-portal to common content associated with that persona.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/website/sitemap.jpg' }]
                            }],
                            [{ text: 'We worked with our COO, using our staff interviews to develop an official voice and company goals. We then began to implement priority keywords derived from the newly created voice where it was relevant and fit the content.' }],
                            [{ text: 'The search element was interacted with more than any other element on the site. We wanted to emphasize the search and leverage the changes we made to the logic. Extending the search to the navigation instead of just the home page allowed visitors to jump to wherever they needed at any time. We also added page-specific JavaScript pagination and search to alleviate the pain of scrolling through endless lists.' }],
                            [{ text: 'To encourage visitor interaction, contact forms were added at the bottom of outline pages and live-chat bots were configured with auto-engagements when visitors would idle on specific pages or visit several different pages in a short duration.' }],
                            [{ text: 'Error pages and warning messages were highly important to informing our visitors and registrants of their status. This was crucial for the registration process were registrants would often abandon the process if they became unsure of their status.' }],
                            [{
                                text: 'An increase in mobile visitors illustrated a need to ensure that the entire site was mobile-friendly. The area that would be most challenging was the schedule. The information density lead to several attempts to minify the data. Mockups and prototypes were especially helpful in designing these elements.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/website/mockups.jpg' }]
                            }],
                            [{ text: 'To increase access to information where it was most valuable, we added a mini schedule to our outline pages that would allow visitors to register directly from the outline page. Filters were also added to some of our more robust partner pages as well as the schedule. We wanted to ensure that visitors had the agency to view and find content the way they wanted.' }],
                            [{ text: 'Our new marketing automation system came with a powerful API that allowed us to connect our CRM, our Marketing System, and our Public website. We looked at the tools provided by the Marketing Automation platform and opted to abandon many of the custom-built CMS pages in favor of leveraging the more optimized and trackable landing pages, forms, and notifications. This would eventually allow us more endpoints to capture visitor data.' }],
                            [{ text: 'During the process of designing the updated website, we identified a few more pain points along the way. Our "bundled" or "parent-child" courses required them to be reimagined. Out of this need we created the tools necessary to bundle any class together and allowed us to create a Bundled Classes section of the website. With predetermined discounts for registering for multiple classes at once, something the sales team did often but the website had never supported.' }]
                        ],
                        delivery: [
                            [{ text: 'We successfully migrated our website to a new technology stack that would be futureproof for the next few years. Having a webserver that supports http/2 on the latest OS with updates was a load off our tech groups shoulders. Having all our systems running the same version of .Net for once was a load off the developer\'s shoulders.' }],
                            [{
                                text: 'Our personas had been loosely defined during ideation and solidified for delivery. After matching up the demographic information from our analytics and registration data, we were able to fully define our three main personas. These personas ended up being the basis for the user flows and content funnels on the site.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/website/ideation-personas.jpg' }]
                            }],
                            [{ text: 'Several new design and informational elements had been added to the new version of the website. We could now list paid and free events that people would be able to register into using the existing portal. We added icons to the schedule to help identify different class types, we also added filters with custom routes so that visitors could view specific types of classes or we could link to a guaranteed to run schedule.' }],
                            [{
                                text: 'Updating the site to be fully responsive meant that it was finally 100% usable on any device, including the schedule and registration process.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/website/screensizes.jpg' }]
                            }],
                            [{ text: 'The registration process had been broken down into steps. A progress bar was added to give registrants an idea of where they were in the registration process and help guide them. Back buttons were added to the bottom of the screen to reduce the number of registrants using their browser back button and resetting their form data.' }],
                            [{ text: 'Places to add contact forms were easily identified and the forms were added in ways to encourage interaction. The process of choosing a live chat for the site warrants its own case study as we tested over a dozen live chat solutions with at least half of those going through multiple weeks of live testing.' }],
                            [{ text: 'The site was finally fully trackable and we could now check the analytics to monitor our user journeys through goals and behavior maps. We were also now gathering conversion data due to the registration page update which we could use to determine ROI for our marketing efforts.' }]
                        ],
                        reflection: [
                            [{ text: 'After publishing the site, engagement and conversions increased while abandonment and overall time on site decreased. This told us that we were getting better visitors to the site and that they were finding what they needed more efficiently. Due to the technology changes we saw a dramatic increase in website performance and delivery.' }],
                            [{
                                text: 'Monitoring the site after launch confirmed several of the team\'s hypotheses, help requests subsided dramatically, the sales team was working more efficiently, our marketing team finally had all the tools they needed and the data to share with our executive team.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/website/icons.jpg' }]
                            }]
                        ]
                    }],
                    skills: ['UX', 'css', 'js', 'html', 'api', 'prototyping', 'a/b testing', 'analytics']
                },
                {
                    csid: 'marketingautomation',
                    title: 'Marketing Automation',
                    titleImage: '/images/portfolio/gallery/dwwtc/email/email2.jpg',
                    details: [{
                        role: 'UX Designer, FE Dev, System Engineer',
                        platforms: 'Web, Server',
                        tools: 'MJML, Foundation, Mautic, API',
                        duration: '4 Months'
                    }],
                    content: [{
                        overview: [
                            [{ text: 'Dynamic Worldwide needed to expand their communication footprint and onboarding for new students as well as increase marketing capabilities.' }]
                        ],
                        goals: [
                            [{ text: 'Our solution would need to send onboarding emails to students after initial registration and exit emails for surveys, marketing messages and allow us to build campaigns with segmented lists. Consolidate metric data and analytics into a single source for tracking. Create and manage market segments.' }]
                        ],
                        challenges: [
                            [{ text: 'The major challenge in this project was linking multiple systems together so that information could be shared between them. Another challenge was creating campaigns that dynamically reacted to user actions and service events.' }]
                        ],
                        solution: [
                            [{ text: 'The cornerstone to our solution would end up being a marketing automation solution called Mautic. Our initial search for email platforms led us to discovering Mautic. It was free, open source, included a powerful API, and supported advanced campaign automation. This meant we would be able to integrate our existing tools directly into our Marketing solution and would result in far less manual data management.' }],
                            [{ text: 'An email processing service was created to listen for requests from our websites and monitor the main database for changes. Emails would then be split and sent to different mail servers based on their sources. This would allow us more granularity for email tracking and ensured that marketing messages would not delay the transactional emails. ' }],
                            [{ text: 'Segmentation was very important for identifying student and registrant needs. We leveraged existing training partners along with company names and job roles to define several segments that we would be able to target and analyze interactions with. We would also be able to dynamically and automatically segment users based on purchase patterns, form interactions, and email domain or company activity.' }],
                            [{
                                text: 'Emails were built in HTML to be responsive with full text fallback using multiple frameworks. While we started with foundation for emails, we ended up migrating to MJML as we found that we could achieve a wide range of layouts using the latter. Emails were created for transactional purposes and weekly as marketing campaign emails. A/B testing was performed for different segments and winners were generally determined during the first quarter to half of the send with the remaining contacts receiving the winning message.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/marketingautomation/htmlemails.jpg' }]
                            }],
                            [{
                                text: 'Campaigns were created to facilitate onboarding, events, marketing messages, and more. Engagement was measured by a point system where each engagement event would grant or remove points based on how that contact reacted. Once contacts reached specified thresholds, they were automatically pushed back to our CRM as active leads. This would allow us to keep our CRM full of active users while maintaining a larger marketing database that would cycle inactive users back into the CRM as their engagement increased.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/marketingautomation/automation-campaigns.jpg' }]
                            }],
                            [{ text: 'Custom reports and contact lists could be generated using Mautic\'s powerful reporting engine.The lists could then be passed to the sales team for follow - up on recently engaged contacts.Campaigns were tracked in google analytics using UTM tags which allowed us to track campaign contacts through their journey on the site.Reports were sent to the executive team and stakeholders for review then generally followed by weekly marketing meetings.' }]
                        ],
                        benefits: [
                            [{ text: 'We were able to achieve full tracking and control for message delivery and segmentation. Emails are now automatically processed based on workflow events. Queued emails are held and can be modified in case of a delivery issue. Marketing messages can be targeted based on any criteria in our databases. Bounce-backs and email delivery failures can be monitored and purged or updated.' }],
                            [{ text: 'Advanced campaign management meant that once the logic was in place we could essentially "set it and forget it" allowing the campaign and subsequent actions to automate much of the engagement and contact management process. Maintaining our own private email server for this allowed us to monitor our sending reputation and deliverability as well.' }]
                        ],
                        results: [
                            [{
                                text: 'Building the email processing service and configuring an integrated marketing automation platform allowed us to take full control of every message that was sent to and from the company. Responsive HTML emails helped to add visual appeal and engage customers regardless of their device. Campaign tools allowed us to strengthen our user experience and gain insights from our interactions and engagements.'
                            }]
                        ],
                    }],
                    skills: ['css', 'html', 'email', 'deliverability', 'tracking']
                },
                {
                    csid: 'ondemand',
                    title: 'On-Demand Solution',
                    titleImage: '/images/portfolio/gallery/dwwtc/livelabs/labs.jpg',
                    details: [{
                        role: 'UX & UI Designer, FE Dev',
                        platforms: 'Web & Mobile',
                        tools: 'Illustrator, Axure, Photoshop, Visual Studio, Guacamole, Moodle',
                        duration: '6 Months'
                    }],
                    content: [{
                        description: [
                            [{ text: 'This solution was built to accomodate changes needed to our flash-based on-demand system. The On-Demand player was created in HTML, CSS, and JS to be responsive and operable on nearly any platform. The lab access was enabled via Guacamole front-end with equipment created and loaded dynamically through the VMware API. This solution was also created to be SCORM compliant and uploaded to a LMS.' }]
                        ],
                        challenges: [
                            [{ text: 'Synchronization of Audio, subtitles, and slides when using the scrub bar. Having the player usable on as many platforms as possible. Can lab configuration and deployment be automated? Ensuring the learning packages were SCORM compliant and compatible with our LMS.' }]
                        ],
                        solution: [
                            [{ text: 'Deciding on a replacement for flash was fairly starightforward as HTML5 brought many useful features. Also, this would enable us to design the labs and lessons with a responsive responsive UI. Synchronization of the audio, slides, and captions was done by setting up appropriate timelines and timestamps for each lesson. Configuration and extraction of timestamps was done programatically to save time and effort. We decided on a SCORM compliant LMS called Moodle which served us well and allowed us to integrate our own data-access service with the registration back-end. Configuring packages in SCORM compliant format and uploading them VIA zip file made updating easy enough for staff to do on their own. Creating and destrying templated virtual machine instances was done via the VMware API and access was handled via the Guacamole front-end that would allow SSH and telent sessions to the lab equipment through the browser.' }]
                        ],
                        benefits: [
                            [{ text: 'Having a SCORM compliant LMS system allowed us to track lesson completion and lab success or failure and report back to the required bodies. Building everything in HTML5 allowed us to design for multiple resolutions, aspect ratios and allowed us to add accessibility options to the lessons. Automating the creation and destruction of lab equipment and lab access meant that the entire solution would be unmanaged aside from initial registration.' }]
                        ],
                        results: [
                            [{
                                text: 'This solution was an ideal replacement for the On-Demand solution created before it. With accessability in mind, we opened the content to a new group of users that had previously been unable to participate in our on-demand learning. Our choice to use the LMS allowed for greater and more accurate reporting.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/livelabs/llplayer.jpg' }, { name: '/images/portfolio/gallery/dwwtc/livelabs/labs.jpg' }]
                            }]
                        ]
                    }],
                    skills: ['css', 'js', 'html', 'audio']
                },
                {
                    csid: 'adminportal',
                    title: 'DWWTC Admin Portal',
                    titleImage: '/images/portfolio/gallery/dwwtc/admin/admin1.jpg',
                    details: [{
                        role: 'UX & UI Designer',
                        platforms: 'Web & Mobile',
                        tools: 'Illustrator, Axure, Photoshop, Visual Studio',
                        duration: '1 Year+    '
                    }],
                    content: [{
                        overview: [
                            [{ text: 'The DWWTC Admin portal grew out of a need to manage content on the public website that we were rebuilding at the time. This portal would eventually become the center of all business conducted with DWWTC.' }]
                        ],
                        requirements: [
                            [{ text: 'One of the first requirements is that this this set of tools would need to be web-based and secure. Several of our employees worked outside of the company headquarters and would need to access tools and information securely.' }],
                            [{ text: 'The second most important requirement was that we would support for managing classes and scheduling as well as registrations. Finally, we would also need to update site content without the need for publishing, this would come in the form of a custom CMS.' }]
                        ],
                        discovery: [
                            [{ text: 'We held employee interviews to and shadowed members of the operations team to understand the steps required to fulfill the registration and delivery processes. Workshops were held with developers and stakeholders to refine business logic from our workflow assessments.' }],
                            [{ text: 'Building the public site in tandem with the admin portal allowed us full control of all the tooling and how it would be implemented. Both sites would rely on a shared data access service and everything could be built using the same C# .Net webforms.' }],
                            [{ text: 'At this point enough data had been acquired to begin construction of the portal. We would identify and define three major implementation phases for the tooling. To start we would need to assess the value and scope of each tool. Items that were not required to fulfill the student journey would be omitted during the initial phase. Other tools would be released as needed or as our phase cycles would complete.' }]
                        ],
                        ideation: [
                            [{ text: 'After research was complete, we still needed to gather information on the desired logical and visual flow of the tooling. We accomplished this by conducting more interviews with the operations team using digital mockups and hand-made visual aids.' }],
                            [{ text: 'Though we wanted to create our tools around current user workflows, we discovered that the business logic would often conflict with the ways in which users wanted to visualize or interact with the tools to accomplish a given task. To solve these problems, we would work closely with the process owner to compromise on a solution. This led to the process owner having a feeling of ownership over their tools.' }],
                            [{ text: 'There was a belief that one day there may not be a development team to push the site whenever a text change needed to be made so we wanted to build in a content management system that could be used by anyone. We would only allow text modifications to retain the structure of the front-end template. We chose to use wiki formatting as a base due to its simplicity and ease-of-use.' }],
                            [{ text: 'With so many tools being created, we would need a way to organize them. We defined several categories based on current job roles; Course management, Scheduling, Registration Management, User Management, Equipment, Finance, Website management, Reports, and finally a super admin set of tools.' }],
                            [{ text: 'However, with so many tools we needed to ensure that users would only have access to the tools and data necessary for their jobs. We would create and define access and update permissions based on job role that would be able to be applied at the user level.' }],
                            [{ text: 'During permission and role definition we discovered that our contract instructor\'s needs were different enough to warrant a separate instructor portal. This would allow us to limit confidential data access to employees only while customizing tooling for the instructors.' }],
                            [{ text: 'At the beginning of the design phase, we wanted to identify our users by technology to gain insight for framing the site contents and required browser support. In our initial discovery we did not identify any mobile users but there were low-resolution users. We decided to design for our lowest known screen size. This would later be changed to allow for mobile access to all the admin tools.' }],
                            [{ text: 'Due to the form-heavy nature of the admin pages, we wanted to ensure that we had plenty of white-space, separation and focus indicators for the input elements. One way we did this was by dividing the inputs up into workflow sections, giving each section a subtitle and divider line.' }],
                            [{
                                text: 'We would iterate on the workflow sections, creating collapsible sections that would save their position (open or closed) to a cookie. This would allow users to collapse workflows that they did not use in a persistent way and keep visual distractions to a minimum. We would also take advantage of CSS pseudo elements to indicate which input was active, hovered, or unavailable.',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/admin/admin-elements.jpg' }]
                            }],
                            [{ text: 'One of the requests from users to was have a calendar view for the schedule. We had initially tried to create our own calendars from scratch but decided that it was too problematic and leveraged the FullCalendar library as it was robust enough to handle our data and functional needs. Initially this would be designed as a modal but we found that this method heavily impacted performance. impact to be overly strenuous so we created a separate class details tool that would be integrated into the calendar.' }],
                            [{ text: 'Finance and reporting were part of the final phase for the project plan. We were using QuickBooks and needed some tools for conversion and data integrity. Workshops were held to identify reporting opportunities based on business logic definitions. Reports were created with excel and PDF exports using google charts.' }],
                            [{ text: 'We created a messaging center in the admin portal to handle workflow notifications. Notifications were designed to handle approvals and/or guide users to the appropriate tool to address the issue. We also built a monitoring service that would watch for database changes and create notifications in the message center or send emails as needed. Examples include sending reminder emails, class access information, invoices, and checking for invalid or missing data.' }]
                        ],
                        results: [
                            [{ text: 'This project allowed us to consolidate all our tools and data into a single place. Previous websites for the company had stored everything as a string. Properly attributing data types in SQL and diligent error checking on the front-end solidified data integrity and allowed us to generate effective and honest reports.' }],
                            [{ text: 'The ability to notify users of workflow events ensured that processes did not fall through the cracks. Designing effective workflows would not have been possible without our interaction with the operations users themselves. Walking through existing processes and workflows with the users helped alleviate our own idiosyncrasies.' }],
                            [{ text: 'Once completed, tool and workflow documentation was added to the company SharePoint site. We then used the documentation to train the employees on the new tools and for future onboarding.' }],
                            [{
                                text: 'The best part of building the admin portal was how much paper we saved!',
                                examples: [{ name: '/images/portfolio/gallery/dwwtc/admin/admin-sizes.jpg' }]
                            }]
                        ]
                    }],
                    skills: ['css', 'js', 'html', 'prototyping']
                },
                {
                    csid: 'dwwmedia',
                    title: 'DWWMedia',
                    titleImage: '/images/portfolio/gallery/print/print1.jpg',
                    details: [{
                        role: 'UX & UI Designer',
                        platforms: 'Digital & Print',
                        tools: 'Illustrator, Photoshop, Visual Studio, InDesign',
                        duration: '2 Years'
                    }],
                    content: [{
                        print: [
                            [{
                                text: 'I have worked on numerous print design projects raning from branding material such as corporate letterhead, business cards to vehicle wraps and large-format posters. A few more frequent examples are brochures, folders, leave-behinds, and print ads.',
                                examples: [{ name: '/images/portfolio/gallery/print/posters.jpg' }, { name: '/images/portfolio/gallery/print/print1.jpg' }]
                            }]
                        ],
                        ambev: [
                            [{
                                text: 'Full corporate rebranding package including website design, vehicle wraps, stationary and logo design.',
                                examples: [{ name: '/images/portfolio/gallery/ambev/ambev-styles.jpg' }, { name: '/images/portfolio/gallery/ambev/ambev-screens.jpg' }]
                            }]
                        ],
                        devastation: [
                            [{
                                text: 'Designed and developed a website, print media, and event media including stands, monitor wraps and more.',
                                examples: [{ name: '/images/portfolio/gallery/devastation/devastationweb.jpg' }, { name: '/images/portfolio/gallery/devastation/devastationprint.jpg' }]
                            }]
                        ],
                        celluwipes: [
                            [{
                                text: 'Designed and developed a simple website with order processing and shot a 45s commercial for the product.',
                                vexamples: ['https://www.youtube.com/embed/M8S_OTOVFAU']
                            }]
                        ],
                        knockout: [
                            [{
                                text: 'Designed and developed an interactive animated website, two 45s commercial spots and 360 degree product shots.',
                                vexamples: ['https://www.youtube.com/embed/98ajH2k6xtA', 'https://www.youtube.com/embed/ZFaKa1czb4o']
                            }]
                        ],
                        stingnlinger: [
                            [{
                                text: 'Designed, configured, and populated an online shopping experience for full product catalog, one 45s commercial spot and product shots.',
                                vexamples: ['https://www.youtube.com/embed/IBEV6UZVub8'],
                                examples: [{ name: '/images/portfolio/gallery/stingnlinger/stingnlingerweb.jpg' }]
                            }]
                        ]
                    }],
                    skills: ['illustrator', 'photoshop', 'photography', 'print']
                }
                ],
                csId: this.$route.params.Cid,
                dataReady: true,
            }
        },
        computed: {
            caseStudyObj: function () {
                var routeId = this.$route.params.Cid;
                var caseStudyObject = [];
                this.casestudyItems.forEach((caseStudy) => {
                    if (routeId == caseStudy.csid) {
                        caseStudyObject = caseStudy;
                    }
                })
                return caseStudyObject;
            },
            contentObj: function() {
                var routeId = this.$route.params.Cid;
                var caseStudyContent = [];
                this.casestudyItems.forEach ((caseStudy) => {
                    if (routeId == caseStudy.csid) {
                        caseStudyContent = caseStudy.content[0];
                    }
                })
                return caseStudyContent;
            }
        },
        created:
            function () {
                var ttlID = this.csId;
                var myCase;
                function csTtl(i) {
                    if (i.csid === ttlID) {
                        return myCase = i;
                    }
                    else if (i.csid !== ttlID) {
                        return myCase = null;
                    }
                }
                //document.title = ttlName + ' - Case Study';
                this.casestudyItems.find(csTtl);
                if (myCase !== null) {
                    let ttlName = this.casestudyItems.find(csTtl).title;
                    document.title = ttlName + ' - Case Study';
                }
                else {
                    return this.$router.push('/404')
                }

        },
        mounted:
            function () {
                var altName = document.getElementsByTagName("img");

                var ttls = document.getElementsByClassName("cs-ttl");

                function randNum() {
                    return Math.floor(Math.random() * (310 - 160) + 160);
                }
                let ttlHue = randNum();
                for (var iS = 0, maxS = ttls.length; iS < maxS; iS++) {
                    ttls[iS].setAttribute("style", "background: linear-gradient(-135deg, hsla(" + ttlHue + ", 80%, 45%, 1) 0%, hsla(" + (ttlHue + 54) + ", 80%, 45%, 1) 100%);");
                }

                function splitName(fileName) {
                    return fileName.src.split('/').pop().replace('.jpg', '');
                }
                for (var iA = 0, maxA = altName.length; iA < maxA; iA++) {
                    altName[iA].setAttribute("alt", splitName(altName[iA]));
                }
        },
        methods: {
            imgShow(imgSrc) {
                this.modalImg = imgSrc;
                this.$modal.show('img-modal');
            },
            vShow(vidSrc) {
                this.modalVid = vidSrc;
                this.$modal.show('vid-modal');
            },
            imgHide() {
                this.$modal.hide('img-modal');                
            },
            vidHide() {
                this.$modal.hide('vid-modal');
            },
            modalAssist() {
                document.body.classList.toggle("vm--modal-overflow");
            }
        },
        metaInfo: {
            title: '',
            titleTemplate: () => {
                
            },
            meta: [
                {
                    name: 'description',
                    content: 'Portfolio and case studies'
                },
                {
                    property: 'og:description',
                    content: 'Portfolio and case studies'
                }
            ]
        }
    }
</script>
